import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import "../../../graphql/fragments"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Cover } from "../../../components/cover/cover"
import { CoverFeatured } from "../../../components/cover-featured/cover-featured"
import { HeadingBasic } from "../../../components/heading/heading"

import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../../components/utils/render-functions"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GreenInitiative = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgBannerSearch: file(relativePath: { eq: "imgBannerSearch1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability1: file(
        relativePath: { eq: "img-sustainbility-social.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability2: file(
        relativePath: { eq: "img-sustainbility-economy.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability3: file(
        relativePath: { eq: "img-sustainbility-environment.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "/sustainability/green-initiative") {
          id
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...AcfIconListBlock
            ...CoreEmbedYoutube
          }
          childPages {
            nodes {
              title
              slug
              excerpt(format: RAW)
              featuredImage {
                sourceUrl
                title
                caption
              }
            }
          }
          seo {
            title
            twitterDescription
            metaDesc
            opengraphDescription
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreCoverBlock
              ...AcfGalleryBlock
              ...CoreGroupBlock
              ...AcfIconListBlock
              ...CoreEmbedYoutube
            }
            seo {
              title
              twitterDescription
              metaDesc
              opengraphDescription
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo.title}
        description={seo.twitterdescriot}
        img={
          cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
        }
        url={location?.href}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Green Initiative"
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container">
                <div className={i === 0 ? "py-main" : "pt-main"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "core/buttons":
            if (
              item.innerBlocks[0].attributes.url &&
              item.innerBlocks[0].attributes.url.split(".").length > 1
            ) {
              return (
                <div key={`button=${i}`} className="container">
                  <div className={i === 0 ? "py-main" : "pb-main"}>
                    <HeadingBasic>
                      <a
                        href={item.innerBlocks[0].attributes.url}
                        target="_blank"
                        className="btn btn-link fx-underline"
                        rel="noopener noreferrer"
                        key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      >
                        {item.innerBlocks[0].attributes.text} &nbsp;
                        <i className="far fa-external-link"></i>
                      </a>
                    </HeadingBasic>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={`button-${i}`} className="container">
                  <Link
                    key={`${item.innerBlocks[0].attributes.url}-${i}`}
                    to={item.innerBlocks[0].attributes.url}
                    className="btn btn-link fx-underline"
                  >
                    {item.innerBlocks[0].attributes.text}
                  </Link>
                </div>
              )
            }
          case "core/paragraph":
            return (
              <div key={`headingbasic-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "core/group":
            return (
              <div key={`${item.name}-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "acf/iconlist":
            return (
              <div
                key={`iconlist-${i}`}
                className={i === 0 ? "container py-main" : "container pb-main"}
              >
                <WpIconlist data={item} />
              </div>
            )
          case "acf/labelvalues":
            return (
              <div key={`${item.name}-${i}`} className="pb-main">
                <Stats data={item} />
              </div>
            )
          case "core/cover":
            if (i !== 0) {
              return (
                <div key={`featured-${i}`} className="pb-main">
                  <CoverFeatured
                    label="Featured News"
                    img={
                      item.attributes.url || data.featured.childImageSharp.fluid
                    }
                    title={
                      item.innerBlocks[0] !== undefined &&
                      item.innerBlocks[0].attributes.content
                    }
                    text={
                      item.innerBlocks[1] !== undefined &&
                      item.innerBlocks[1].attributes.content
                    }
                    btnText={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.text
                    }
                    btnLink={`/development/office/${(item.innerBlocks[2] !==
                      undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.url) ||
                      ""}`}
                  />
                </div>
              )
            }
            return <div key={`empty-${i}`}></div>
          case "acf/acfgallery":
            return (
              <div key={`gallery-${i}`} className="container">
                <Carousel data={item} />
              </div>
            )
          default:
            return <div key={`default-${i}`}>{item.name}</div>
        }
      })}
    </Layout>
  )
}

export default GreenInitiative
